import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { join, map, path, pipe, prop, propOr } from 'ramda'
import { generatePath } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import { MoreListButton, NewButton } from '~/components/Buttons'
import { ActiveStatus } from '~/components/ActiveStatus'
import { RETAILER, TYPES } from '~/constants/companyTypes'
import * as ROUTES from '~/constants/routes'
import * as NAV from '~/constants/nav-titles'
import PageTitle from '~/components/PageTitle'
import Container, { Content, Header } from '~/components/Container'
import TextOverflow from '~/components/TextOverflow'
import TableDateFormat from '~/components/TableDateFormat'
import { useProfile } from '~/components/Profile'
import { Table, TableHeader } from '~/components/Table'
import TableLink from '~/components/Link'
import { CONTRACT_UPDATE_TABS } from '~/constants/tabs'

import InvitationStatus from './InvitationStatus'

const getWarehouses = pipe(
  propOr([], 'warehouses'),
  map(prop('name')),
  join(', ')
)

const getDetailPath = id => {
  return generatePath(ROUTES.CONTRACT_UPDATE_PATH, { id, tab: CONTRACT_UPDATE_TABS.GENERAL })
}

export const CONTRACT_COLUMNS = [
  {
    width: 200,
    headerName: 'Company',
    field: 'name',
    renderCell: ({ value, row }) => (
      <TableLink to={row.link}>
        <TextOverflow selfTooltip={true}>{value}</TextOverflow>
      </TableLink>
    )
  },
  {
    width: 150,
    headerName: 'Account type',
    field: 'accountType',
    renderCell: ({ value }) => (
      <TextOverflow selfTooltip={true}>{value}</TextOverflow>
    )
  },
  {
    width: 150,
    headerName: 'Status',
    field: 'status',
    renderCell: ({ value }) => (
      <ActiveStatus value={value} />
    )
  },
  {
    width: 150,
    headerName: 'Invitation status',
    field: 'invitationStatus',
    renderCell: ({ value }) => (
      <InvitationStatus value={value} />
    )
  },
  {
    width: 200,
    headerName: 'Email',
    field: 'email',
    renderCell: ({ value }) => (
      <TextOverflow selfTooltip={true}>{value}</TextOverflow>
    )
  },
  {
    width: 300,
    headerName: 'Shared warehouse',
    field: 'sharedWarehouse',
    renderCell: ({ value }) => (
      <TextOverflow selfTooltip={true}>{value}</TextOverflow>
    )
  },
  {
    width: 200,
    headerName: 'Created date',
    field: 'createdAt',
    renderCell: ({ value }) => (
      <TableDateFormat withTime={true} date={value} />
    )
  }
]

const getRowsFromResults = map(item => {
  const id = prop('id', item)
  const client = prop('client', item)
  const company = prop('company', item)
  const isOwner = prop('isOwner', item)

  const name = isOwner
    ? prop('name', client)
    : prop('name', company)

  const backendType = isOwner
    ? prop('type', client)
    : prop('type', company)

  const accountType = TYPES[backendType]

  const status = prop('status', item)
  const sharedWarehouse = getWarehouses(item)

  const invitationStatus = prop('invitationStatus', item)
  const createdAt = prop('createdAt', item)
  const email = prop('toEmail', item)
  const link = getDetailPath(id)

  return {
    id,
    name,
    accountType,
    email,
    status,
    sharedWarehouse,
    invitationStatus,
    createdAt,
    link
  }
})

function ContractTable ({ list, filter, ordering, onListRefetch }) {
  const [tableRef, setTableRef] = useState(null)
  const { t } = useTranslation()
  const { profile } = useProfile()
  const companyType = path(['company', 'type'], profile)
  const disabled = companyType === RETAILER

  const isLoading = prop('isLoading', list)
  const results = prop('results', list)
  const count = prop('count', list)
  const columns = prop('filteredColumns', ordering)

  return (
    <Container>
      <Header>
        <PageTitle
          pageTitle={t('Contracts')}
          parentTitle={NAV.CONFIGURATION}
          disabled={disabled}
          rightButton={(
            <>
              <NewButton path={ROUTES.CONTRACT_CREATE_PATH} />
              <NewButton path={ROUTES.CONTRACT_CREATE_WITH_RETAILER_PATH}>{t('New Retailer')}</NewButton>
            </>
          )}
        />
      </Header>

      <Content>
        <TableHeader
          filter={filter}
          filterOpen={filter.handleOpen}
          orderingOpen={ordering.handleOpen}
          ordering={ordering}
        >
          <MoreListButton tableRef={tableRef} />
        </TableHeader>
        <Table
          isLoading={isLoading}
          columns={columns}
          getRowsFromResults={getRowsFromResults}
          results={results}
          count={count}
          pinnedColumns={['name']}
          checkboxSelection={true}
          ordering={ordering}
          onRefetch={onListRefetch}
          setTableRef={setTableRef}
        />
      </Content>
    </Container>
  )
}

ContractTable.propTypes = {
  list: PropTypes.object.isRequired,
  filter: PropTypes.object.isRequired,
  ordering: PropTypes.object.isRequired,
  onListRefetch: PropTypes.func.isRequired
}

export default ContractTable
