import React, { useRef, useState } from 'react'
import { defaultTo, equals, find, path, pipe, prop, propOr } from 'ramda'
import PropTypes from 'prop-types'
import TextField from '@mui/material/TextField'
import Autocomplete from '@mui/material/Autocomplete'
import Chip from '@mui/material/Chip'
import { useDeepCompareEffect, useRequest } from 'storfox-api-hooks'
import CircularProgress from '@mui/material/CircularProgress/CircularProgress'
import Checkbox from '@mui/material/Checkbox'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'

import useDebounce from '~/hooks/useDebounce'

const getItemFromResults = (results, id, key) =>
  find(
    pipe(
      prop(key),
      equals(id)
    )
  )(results)

function MultiSearchChipField (props) {
  const {
    api,
    input,
    meta,
    InputProps,
    PaperComponent,
    ListboxProps,
    ListboxComponent,
    renderOption,
    searchFieldProps,
    disableCloseOnSelect,
    primaryKey = 'id',
    params = { limit: 30 },
    fullWidth = true,
    isLoading = false,
    size = 'small',
    variant = 'outlined',
    onChange = () => {},
    onValueChange = () => {},
    value = [],
    ...defaultProps
  } = props

  const request = useRequest()
  const [inputValue, setInputValue] = useState('')
  const debouncedValue = useDebounce(inputValue)
  const [loading, setLoading] = useState(false)
  const [options, setOptions] = useState([])
  const [searchedOptions, setSearchedOptions] = useState([])
  const ref = useRef([])

  useDeepCompareEffect(() => {
    setLoading(true)
    request.get(api, params)
      .then(response => {
        const results = defaultTo([], path(['data', 'results'], response))
        const newResults = results.map((item) => propOr('', 'name', item))
        setOptions(newResults)
        setLoading(false)
      })
      .catch(() => setLoading(false))
  }, [api, params, request])

  useDeepCompareEffect(() => {
    if (debouncedValue) {
      setSearchedOptions(options.filter((value) => value.toLowerCase().includes(debouncedValue.toLowerCase())))
    } else {
      setSearchedOptions(options)
    }
  }, [options, debouncedValue])

  return (
    <Autocomplete
      multiple={true}
      options={searchedOptions}
      freeSolo={true}
      renderTags={(value, getTagProps) =>
        value.map((option, index) => (
          <Chip size="small" label={option} {...getTagProps({ index })} />
        ))
      }
      value={value || []}
      onInputChange={(event, value) => {
        setInputValue(value)
      }}
      onChange={(event, value) => {
        if (value) {
          onChange(event, getItemFromResults(ref.current, value[primaryKey], primaryKey))
        } else {
          onChange(event, null)
        }
        onValueChange(value)
      }}
      sx={{
        display: 'inline-block',
        minWidth: '300px',
        maxWidth: '300px'
      }}
      renderInput={(params) => (
        <Box sx={{ display: 'flex' }}>
          <TextField
            {...params}
            {...defaultProps}
            size={size}
            variant={variant}
            fullWidth={fullWidth}
            InputProps={{
              ...params.InputProps,
              ...InputProps,
              endAdornment: (
                <>
                  {(isLoading || loading) ? <CircularProgress color="inherit" size={20} /> : null}
                  {params.InputProps.endAdornment}
                </>
              )
            }}
          />
          {options.length > 0 && (
            <Box sx={{ display: 'flex', alignItems: 'flex-start' }}>
              <Checkbox
                checked={value.length === options.length}
                onChange={() => {
                  if (value.length === options.length) {
                    onValueChange([])
                  } else {
                    onValueChange(options)
                  }
                }}
              />
              <Typography sx={{ width: 'max-content', paddingTop: '10px' }}>Select All</Typography>
            </Box>
          )}
        </Box>
      )}
    />
  )
}

MultiSearchChipField.propTypes = {
  primaryKey: PropTypes.string.isRequired,
  api: PropTypes.string.isRequired,
  params: PropTypes.object.isRequired,
  size: PropTypes.string.isRequired,
  fullWidth: PropTypes.bool.isRequired,
  variant: PropTypes.string.isRequired,
  disabled: PropTypes.bool.isRequired,
  input: PropTypes.object.isRequired,
  renderOption: PropTypes.func,
  isLoading: PropTypes.bool.isRequired,
  meta: PropTypes.object.isRequired,
  disableClearable: PropTypes.bool.isRequired,
  InputProps: PropTypes.object,
  onChange: PropTypes.func.isRequired,
  PaperComponent: PropTypes.func,
  ListboxComponent: PropTypes.func,
  ListboxProps: PropTypes.object,
  onValueChange: PropTypes.func,
  disableCloseOnSelect: PropTypes.bool,
  value: PropTypes.string.isRequired,
  searchFieldProps: PropTypes.shape({
    PopperComponent: PropTypes.func
  }),
}

MultiSearchChipField.defaultProps = {
  primaryKey: 'id',
  disabled: false,
  isLoading: false,
  disableClearable: false,
  onChange: () => {},
  onValueChange: () => {}
}

export default MultiSearchChipField
