import React from 'react'
import PropTypes from 'prop-types'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import Divider from '@mui/material/Divider'
import { useTranslation } from 'react-i18next'
import { styled } from '@mui/material'

import EmailField from '~/components/Fields/EmailField'
import MultiSelectSearchField from '~/components/Fields/MultiSelectSearchField'
import { CardHeader } from '~/components/Cards'
import { Button, DiscardButton } from '~/components/Buttons'
import { withForm } from '~/components/Form'
import PageTitle from '~/components/PageTitle'
import Container, { Content, Header } from '~/components/Container'
import * as NAV from '~/constants/nav-titles'
import * as API from '~/constants/api'
import CountryField from '~/components/Fields/CountryField'
import TextField from '~/components/Fields/TextField'
import StrongPasswordField from '~/modules/auth/components/Fields/StrongPasswordField'
import PhoneNumberField from '~/components/Fields/PhoneNumberField'

import BillingField from '../Fields/BillingField'

const GridStyled = styled(Grid)({
  '.MuiInputBase-root': {
    height: '37px'
  },
  '.MuiInputBase-input': {
    height: '6px'
  }
})

const CountryGridStyled = styled(Grid)({
  '.MuiInputBase-root': {
    height: '37px'
  },
  '.MuiInputBase-input': {
    height: '6px'
  },
  'label': {
    transform: 'translate(14px, 8px) scale(1)'
  },
  '.MuiInputLabel-shrink': {
    transform: 'translate(14px, -9px) scale(0.75)'
  }
})

const PhoneGrid = styled(Grid)({
  '.form-control': {
    height: '37px'
  }
})

function ContractCreateWithRetailerForm (props) {
  const {
    pageTitle,
    pageTitleLoading,
    isLoading,
    form
  } = props
  const { t } = useTranslation()

  return (
    <form onSubmit={form.handleSubmit}>
      <Container>
        <Header>
          <PageTitle
            pageTitle={pageTitle}
            pageTitleLoading={pageTitleLoading}
            parentTitle={NAV.CONFIGURATION}
            rightButton={(
              <>
                <DiscardButton disabled={isLoading} />
                <Button
                  variant="contained"
                  type="submit"
                  data-cy="submit"
                  disabled={isLoading}
                >
                  {t('Create')}
                </Button>
              </>
            )}
          />
        </Header>

        <Content>
          <Grid container={true} spacing={3}>
            <Grid item={true} xs={12} lg={8}>
              <Box mb={3}>
                <Card>
                  <CardHeader title={t('Invite')} />
                  <Divider />
                  <CardContent>
                    <Grid container={true} spacing={3}>
                      <Grid item={true} xs={12}>
                        <TextField
                          data-cy="name"
                          name="retailer.name"
                          label="Name"
                        />
                      </Grid>
                      <Grid item={true} xs={6}>
                        <EmailField
                          data-cy="email"
                          name="retailer.email"
                          label="Email"
                        />
                      </Grid>
                      <GridStyled item={true} xs={6}>
                        <StrongPasswordField
                          data-cy="password"
                          autoComplete="password"
                          name="retailer.password"
                          style={{ fontWeight: 'bold' }}
                        />
                      </GridStyled>
                      <CountryGridStyled item={true} xs={6}>
                        <CountryField
                          name="retailer.country"
                          data-cy="country"
                          label={t('Country')}
                          size="medium"
                        />
                      </CountryGridStyled>
                      <PhoneGrid item={true} xs={6}>
                        <PhoneNumberField
                          name="retailer.phone"
                          data-cy="phone"
                          label={t('Phone Number')}
                          size="medium"
                        />
                      </PhoneGrid>
                      <Grid item={true} xs={6}>
                        <TextField
                          data-cy="firstName"
                          name="retailer.firstName"
                          label="First Name"
                        />
                      </Grid>
                      <Grid item={true} xs={6}>
                        <TextField
                          data-cy="lastName"
                          name="retailer.lastName"
                          label="Last Name"
                        />
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              </Box>
            </Grid>

            <Grid item={true} xs={12} lg={4}>
              <Box mb={3}>
                <Card>
                  <CardHeader title={t('Settings')} />
                  <Divider />
                  <CardContent>
                    <Grid container={true} spacing={3}>
                      <Grid item={true} xs={12}>
                        <MultiSelectSearchField
                          api={API.WAREHOUSE_LIST}
                          data-cy="warehouses"
                          name="warehouses"
                          label={t('Shared Warehouses')}
                          ListboxProps={{ 'data-cy': 'warehouseList' }}
                        />
                      </Grid>

                      <Grid item={true} xs={12}>
                        <BillingField />
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              </Box>
            </Grid>
          </Grid>

        </Content>
      </Container>
    </form>
  )
}

ContractCreateWithRetailerForm.propTypes = {
  pageTitle: PropTypes.string,
  pageTitleLoading: PropTypes.bool.isRequired,
  form: PropTypes.object.isRequired,
  isLoading: PropTypes.bool.isRequired
}

export default withForm(ContractCreateWithRetailerForm)
