import React from 'react'
import { prop } from 'ramda'
import PropTypes from 'prop-types'
import { generatePath, useParams } from 'react-router-dom'

import PageTitle from '~/components/PageTitle'
import { EditButton } from '~/components/Buttons'
import Container, { Content, Header } from '~/components/Container'
import Hide from '~/components/Hide'
import { useCompany } from '~/components/Profile'
import * as NAV from '~/constants/nav-titles'
import { PURCHASE_ORDER_STATUSES } from '~/constants/statuses'
import { PURCHASE_ORDER_DETAIL_TABS } from '~/constants/tabs'
import Button from '~/components/Buttons/Button'
import LoaderProvider from '~/components/Loader'
import * as ROUTES from '~/constants/routes'
import useDialog from '~/hooks/useDialog'
import History from '~/components/History'

import GatePassCreateDialog from './GatePassCreateDialog'
import PurchaseOrderGatePass from './PurchaseOrderGatePass'
import More from './More'
import PurchaseOrderDetailTabs from './PurchaseOrderDetailTabs'
import PurchaseOrderGeneral from './PurchaseOrderGeneral'

import ReceiveButton from '../Buttons/ReceiveButton'

const COMPLETED = 'completed'

function PurchaseOrderDetail (props) {
  const {
    detail,
    pageTitle,
    pageTitleLoading,
    isLoading,
    purchaseHistory,
    purchaseGatePassList,
    onProgress,
    onEmailSend,
    onGatepassGenerate,
    purchaseOrderOnClose,
    purchaseOrderOnCancel,
    onMarkAsPaid,
    onDownload,
    onInvoiceGenerate,
    onReturnCreate
  } = props

  const { guid, tab } = useParams()
  const { isRetailer } = useCompany()
  const gatepassDialog = useDialog()

  const receiptGuid = prop('receiptGuid', detail)
  const status = prop('orderStatus', detail)
  const lineItems = prop('lineItems', detail)

  const receiptStatus = prop('receiptStatus', detail)
  const hasGatepass = prop('hasGatepass', detail)

  const returnDisabled = (
    isLoading ||
    status === PURCHASE_ORDER_STATUSES.NEW ||
    status === PURCHASE_ORDER_STATUSES.PROGRESS ||
    status === PURCHASE_ORDER_STATUSES.CANCELLED
  )

  const disabled = (
    isLoading ||
    status === PURCHASE_ORDER_STATUSES.CANCELLED ||
    status === PURCHASE_ORDER_STATUSES.CLOSED
  )

  const receiveDisabled = (
    disabled ||
    receiptStatus === COMPLETED
  )

  const progressHidden = status !== PURCHASE_ORDER_STATUSES.NEW
  const receiveHidden = Boolean(
    isRetailer ||
    hasGatepass ||
    status === PURCHASE_ORDER_STATUSES.NEW
  )

  const editPath = generatePath(ROUTES.PURCHASE_ORDER_UPDATE_PATH, { guid })

  const handleGatepassOpen = () => {
    gatepassDialog.handleOpen()
  }

  const handleCreateGatePass = formValues => {
    const values = { ...formValues }
    return onGatepassGenerate(values)
      .then(() => {
        gatepassDialog.handleClose()
        return Promise.resolve()
      })
  }

  return (
    <LoaderProvider isLoading={isLoading}>
      <Container>
        <Header>
          <PageTitle
            pageTitle={pageTitle}
            pageTitleLoading={pageTitleLoading}
            parentTitle={NAV.PURCHASE_ORDER}
            rightButton={(
              <>
                <Hide hidden={receiveHidden}>
                  <ReceiveButton
                    data-cy="receive"
                    guid={receiptGuid}
                    disabled={receiveDisabled}
                  />
                </Hide>
                <Hide hidden={progressHidden}>
                  <Button
                    variant="contained"
                    data-cy="progress"
                    disabled={isLoading}
                    onClick={onProgress}
                  >
                    Process
                  </Button>
                </Hide>
                <EditButton path={editPath} />
                <More
                  markAsPaidDisabled={disabled}
                  purchaseOrderOnCloseDisabled={disabled}
                  purchaseOrderOnCancelDisabled={disabled}
                  purchaseOrderOnClose={purchaseOrderOnClose}
                  purchaseOrderOnCancel={purchaseOrderOnCancel}
                  onGatepassGenerate={handleGatepassOpen}
                  onInvoiceGenerate={onInvoiceGenerate}
                  onMarkAsPaid={onMarkAsPaid}
                  onEmailSend={onEmailSend}
                  onDownload={onDownload}
                  onReturnCreate={onReturnCreate}
                  returnDisabled={returnDisabled}
                />
              </>
            )}
          />
        </Header>

        <Content>
          <PurchaseOrderDetailTabs value={tab} />
          {tab === PURCHASE_ORDER_DETAIL_TABS.GENERAL && <PurchaseOrderGeneral detail={detail} />}
          {tab === PURCHASE_ORDER_DETAIL_TABS.GATE_PASS && (
            <PurchaseOrderGatePass gatePassList={purchaseGatePassList} />
          )}
          {tab === PURCHASE_ORDER_DETAIL_TABS.HISTORY && <History history={purchaseHistory} />}
        </Content>
      </Container>

      <GatePassCreateDialog
        isLoading={isLoading}
        initialValues={{ lineItems, open: gatepassDialog.open }}
        onClose={gatepassDialog.handleClose}
        onSubmit={handleCreateGatePass}
        open={gatepassDialog.open}
      />
    </LoaderProvider>
  )
}

PurchaseOrderDetail.propTypes = {
  detail: PropTypes.object.isRequired,
  isLoading: PropTypes.bool.isRequired,
  pageTitle: PropTypes.node.isRequired,
  pageTitleLoading: PropTypes.bool.isRequired,
  purchaseOrderOnClose: PropTypes.func.isRequired,
  purchaseOrderOnCancel: PropTypes.func.isRequired,
  onMarkAsPaid: PropTypes.func.isRequired,
  onInvoiceGenerate: PropTypes.func.isRequired,
  onEmailSend: PropTypes.func.isRequired,
  onDownload: PropTypes.func.isRequired,
  onGatepassGenerate: PropTypes.func.isRequired,
  onProgress: PropTypes.func.isRequired,
  purchaseGatePassList: PropTypes.object.isRequired,
  purchaseHistory: PropTypes.object,
  onReturnCreate: PropTypes.func
}

export default PurchaseOrderDetail
