
// AUTH

export const LOGIN = `/v1/login/`
export const LOGIN_BY_CONTRACT = `/v1/login-by-contract/`
export const LOGOUT = `/v1/logout/`
export const SIGN_UP = `/v1/register/`
export const FORGOT_PASSWORD = `/v1/forgot-password/`
export const RESET_PASSWORD = `/v1/reset-password/`
export const ME = `/v1/me/`
export const AGGRAK_AUTH = `/v1/oauth2/aggrakid/`
export const AGGRAK_CALLBACK = `/v1/oauth2/aggrakid/callback/`
export const CHECK_EMAIL = `/v1/check-email/`
export const AUTH_2_FACTOR_VERIFY = `/v1/login-by-2fa/`
export const AUTH_2_FACTOR_RESEND = `/v1/login-by-2fa/resend-code/`

// DASHBOARD
export const DASHBOARD_OVERVIEW = `/v1/reports/overview/`
export const OVERVIEW_GMS_DTD = `/v1/reports/gmsdtd/`
export const OVERVIEW_ORDER_BY_CHANNEL = `/v1/reports/order-by-channels-overview/`
export const OVERVIEW_CARRIER_DIVISION = `/v1/reports/carrier-division/`
export const OVERVIEW_FLOW_OVERVIEW = `/v1/reports/stock-flow-overview/`
export const OVERVIEW_INFORMATION = `/v1/reports/sales-overview/`
export const OVERVIEW_LOW_STOCK = `/v1/reports/low-stock-trending-variants/`
export const OVERVIEW_STOCK = `/v1/reports/stock-overview/`
export const OVERVIEW_ORDER = `/v1/reports/order-overview/`
export const OVERVIEW_SHIPMENTS = `/v1/reports/shipment-overview/`
export const OVERVIEW_ORDERS_BY_UNITS = `/v1/reports/order-by-units-overview/`
export const OVERVIEW_PO_BY_UNITS = `/v1/reports/purchase-by-units-overview/`
export const OVERVIEW_PICKER_STATISTIC = `/v1/reports/picker-statistics/`
export const OVERVIEW_PACKER_STATISTIC = `/v1/reports/packer-statistics/`
export const OVERVIEW_RECEIVE_STATISTIC = `/v1/reports/receiver-statistics/`
export const OVERVIEW_PUTAWAY_STATISTIC = `/v1/reports/putter-statistics/`

// SALES_OVERVIEW
export const SALES_OVERVIEW = `/v1/reports/sales/overview/date/`
export const SALES_OVERVIEW_EXPORT = `/v1/reports/sales/overview/date/export/`

// SALES_OVERVIEW_VARIANTS
export const SALES_MARGIN_OVERVIEW = `/v1/reports/sales/overview/variant/`
export const SALES_MARGIN_EXPORT = `/v1/reports/sales/overview/variant/export/`

// TEAM METRICS
export const TEAM_METRICS_LIST = '/v1/reports/team-metrics/list/'
export const TEAM_METRICS_EXPORT = `/v1/reports/team-metrics/generate-excel/`

// STORAGE REPORT
export const STORAGE_REPORT_DETAIL = '/v1/stock/storage/report/'

// UNIT HISTORY
export const UNIT_HISTORY_REPORT_LIST = `/v1/reports/stock/inventory-history/`
export const UNIT_HISTORY_REPORT_CREATE = `/v1/reports/stock/inventory-history/`

// PURCHASE RECEIVE REPORT
export const PURCHASE_REPORT_LIST = `/v1/reports/receipt/purchase/`
export const PURCHASE_REPORT_CREATE = `/v1/reports/receipt/purchase/generate-report/`

// ORDER UNITS REPORT
export const ORDER_UNITS_REPORT_LIST = `/v1/reports/order/units/list/`
export const ORDER_UNITS_REPORT_CREATE = `/v1/reports/order/units/generate_report/`

// ORDER
export const SALE_ORDER_SEARCH_LIST = `/v1/search/sale-order/list/`
export const SALE_ORDER_LIST = `/v1/orders/list/`
export const SALE_ORDER_CREATE = `/v1/orders/create/`
export const SALE_ORDER_UPDATE = `/v1/orders/update/%s/`
export const SALE_ORDER_DELETE = `/v1/orders/delete/`
export const SALE_ORDER_DETAIL = `/v1/orders/detail/%s/`
export const SALE_ORDER_CUSTOMER = `/v1/orders/customers/list/`
export const SALE_ORDERS_PROCESS = `/v1/orders/process/`
export const SALE_ORDERS_RESOLVE = `/v1/orders/id/%s/resolve/`
export const SALE_ORDER_SKIP_PROCESS = `/v1/orders/guid/%s/skip-process/`
export const SALE_ORDERS_ALLOCATE = `/v1/orders/allocate/`
export const SALE_ORDERS_RELEASE = `/v1/orders/release/%s`
export const SALE_ORDER_MARK_AS_PAID = `/v1/orders/guid/%s/mark-as-paid/`
export const SALE_ORDER_HISTORY = `/v1/history/sale-order/%s/list/`
export const SALE_ORDER_CHANNEL = `/v1/orders/channels/list/`
export const SALE_ORDER_PROCESS = `/v1/orders/id/%s/process/`
export const SALE_ORDER_CANCEL = `/v1/orders/id/%s/cancel/`
export const SALE_ORDER_WAREHOUSE = `/v1/orders/warehouses/list/`
export const SALE_ORDER_VARIANTS = `/v1/orders/variants/list/`
export const SALE_ORDER_TAX_LIST = `/v1/orders/taxes/list/`
export const SALE_ORDER_FILE_ATTACH = `/v1/orders/%s/set-attachment/`
export const SALE_ORDER_FILE_ATTACH_REMOVE = `/v1/orders/%s/remove-attachment/`
export const SALE_ORDER_MANUAL_UNITS = `/v1/stock/sale-orders/%s/list-reservable-units/`
export const SALE_ORDER_MANUAL_ALLOCATE = `/v1/stock/sale-orders/%s/reserve-units/`
export const SALE_ORDER_BULK_WAREHOUSE_SET = `/v1/orders/bulk-set-warehouse/`

export const ORDER_COMMENTS = `/v1/orders/%s/comments/list/`
export const ORDER_SEND_COMMENTS = `/v1/orders/%s/comments/create/`
export const ORDER_CONDITIONS = `/v1/orders/conditions/list/`
export const ORDER_COMPANIES = `/v1/orders/contract-companies/list/`
export const ORDER_SPLIT = `/v1/orders/guid/%s/split-order/`
export const ORDER_DELIVERY_METHOD = `/v1/orders/delivery-method/list/`
export const ORDER_PAYMENT_TYPE_UPDATE = '/v1/orders/set-payment-type/'
export const ORDER_REPORT_GENERATE = `/v1/reports/orders/generate-report/`
export const ORDER_BULK_CANCEL = `/v1/orders/bulk-cancel/`
// CUSTOMER
export const CUSTOMER_LIST = `/v1/orders/customers/list/`
export const CUSTOMER_CREATE = `/v1/orders/customers/create/`
export const CUSTOMER_DETAIL = `/v1/orders/customers/detail/%s/`
export const CUSTOMER_UPDATE = `/v1/orders/customers/update/%s/`
export const CUSTOMER_DELETE = `/v1/orders/customers/delete/`
export const CUSTOMER_HISTORY = `/v1/history/customer/%s/list/`

// BUCKET
export const BUCKET_LIST = `/v1/cross-docking/buckets/list/`
export const BUCKET_CREATE = `/v1/cross-docking/buckets/create/`
export const BUCKET_DETAIL = `/v1/cross-docking/buckets/detail/%s/`
export const BUCKET_UPDATE = `/v1/cross-docking/buckets/update/%s/`
export const BUCKET_DELETE = `/v1/cross-docking/buckets/delete/%s/`

// LOT SHEETS
export const LOT_SHEETS_LIST = `/v1/cross-docking/lots/list/`
export const LOT_SHEETS_DELETE = `/v1/cross-docking/lots/delete/%s/`
export const LOT_SHEETS_UPLOAD = `/v1/cross-docking/lots/upload-lotsheet/`
export const LOT_SHEETS_FILE_VALIDATE = `/v1/cross-docking/lots/validate-lotsheet/`
export const LOT_SHEETS_DETAIL = `/v1/cross-docking/lots/%s/lot-variants/list/`
export const LOT_SHEETS_SCAN = `/v1/cross-docking/lots/%s/lot-variants/items/scan/`
export const LOT_BUCKET_LIST = `/v1/cross-docking/lots/%s/lot-buckets/list/`
export const LOT_NUMBER_BUCKET_LIST = `/v1/cross-docking/lots/:guid/lot-bucket-containers/:bucketGuid/list/`
export const LOT_SHIPMENT_CREATE = `/v1/cross-docking/shipments/create/`
export const LOT_SHEETS_HISTORY = `/v1/cross-docking/lots/%s/scan-history/list/`
export const LOT_SCAN_REPORT_GENERATE = `/v1/cross-docking/lots/%s/generate-lot-scan-report/`
export const LOT_SUMMARY_REPORT_GENERATE = `/v1/cross-docking/lots/%s/generate-lot-summary-report/`
export const LOT_SHEETS_MODIFY_ITEM = `/v1/cross-docking/lots/%s/lot-variants/update/`

// CROSS BUCKET
export const CROSS_SHIPMENT_LIST = `/v1/cross-docking/shipments/list/`

// COMPANY
export const COMPANY_LIST = `/v1/iam/companies/list/`
export const COMPANY_CREATE = `/v1/iam/companies/create/`
export const COMPANY_DETAIL = `/v1/iam/companies/detail/%s/`
export const COMPANY_UPDATE = `/v1/iam/companies/update/%s/`
export const COMPANY_WAREHOUSE_LIST = `/v1/iam/companies/warehouses/list/`

// INTEGRATION
export const INTEGRATION_LIST = `/v1/integrations/list/`
export const INTEGRATION_HISTORY = `/v1/history/event-history/%s/`

export const JT_EXPRESS_CREATE = '/v1/integrations/jnt/create/'
export const JT_EXPRESS_DETAIL = `/v1/integrations/jnt/detail/%s/`
export const JT_EXPRESS_UPDATE = `/v1/integrations/jnt/update/%s/`
export const JT_EXPRESS_DELETE = `/v1/integrations/jnt/delete/`

export const INTEGRATION_CREATE = `/v1/integrations/create/`
export const SHIPOX_CREATE = `/v1/integrations/shipox/create/`
export const SHIPOX_DETAIL = `/v1/integrations/shipox/detail/%s/`
export const SHIPOX_UPDATE = `/v1/integrations/shipox/update/%s/`
export const SHIPOX_DELETE = `/v1/integrations/shipox/delete/`

export const IMILE_CREATE = `/v1/integrations/imile/create/`
export const IMILE_DETAIL = `/v1/integrations/imile/detail/%s/`
export const IMILE_UPDATE = `/v1/integrations/imile/update/%s/`
export const IMILE_DELETE = `/v1/integrations/imile/delete/`

export const ELITE_CREATE = `/v1/integrations/elite/create/`
export const ELITE_DETAIL = `/v1/integrations/elite/detail/%s/`
export const ELITE_UPDATE = `/v1/integrations/elite/update/%s/`
export const ELITE_DELETE = `/v1/integrations/elite/delete/`

export const TCS_CREATE = `/v1/integrations/tcs/create/`
export const TCS_DETAIL = `/v1/integrations/tcs/detail/%s/`
export const TCS_UPDATE = `/v1/integrations/tcs/update/%s/`
export const TCS_DELETE = `/v1/integrations/tcs/delete/`

export const LCS_CREATE = `/v1/integrations/lcs/create/`
export const LCS_DETAIL = `/v1/integrations/lcs/detail/%s/`
export const LCS_UPDATE = `/v1/integrations/lcs/update/%s/`
export const LCS_DELETE = `/v1/integrations/lcs/delete/`

export const CALL_COURIER_CREATE = `/v1/integrations/call-courier/create/`
export const CALL_COURIER_DETAIL = `/v1/integrations/call-courier/detail/%s/`
export const CALL_COURIER_UPDATE = `/v1/integrations/call-courier/update/%s/`
export const CALL_COURIER_DELETE = `/v1/integrations/call-courier/delete/`

export const RIDER_CREATE = `/v1/integrations/rider/create/`
export const RIDER_DETAIL = `/v1/integrations/rider/detail/%s/`
export const RIDER_UPDATE = `/v1/integrations/rider/update/%s/`
export const RIDER_DELETE = `/v1/integrations/rider/delete/`

export const SHIPROCKET_CREATE = '/v1/integrations/shiprocket/create/'
export const SHIPROCKET_DETAIL = `/v1/integrations/shiprocket/detail/%s/`
export const SHIPROCKET_UPDATE = `/v1/integrations/shiprocket/update/%s/`
export const SHIPROCKET_DELETE = `/v1/integrations/shiprocket/delete/`

export const SHOPIFY_INSTALL = `/v1/shopify/install/`
export const SHOPIFY_CREATE = `/v1/integrations/shopify/create/`
export const SHOPIFY_DETAIL = `/v1/integrations/shopify/detail/%s/`
export const SHOPIFY_RETRY_ORDER = `/v1/integrations/shopify/%s/retry-order/`
export const SHOPIFY_UPDATE = `/v1/integrations/shopify/update/%s/`
export const SHOPIFY_DELETE = `/v1/integrations/shopify/delete/`
export const SHOPIFY_PRODUCTS_SYNC = `/v1/integrations/shopify/%s/sync-shopify-products/`

export const SALLA_CREATE = `/v1/integrations/salla/create/`
export const SALLA_DETAIL = `/v1/integrations/salla/detail/%s/`
export const SALLA_UPDATE = `/v1/integrations/salla/update/%s/`
export const SALLA_DELETE = `/v1/integrations/salla/delete/`
export const SALLA_RETRY_ORDER = `/v1/integrations/salla/%s/retry-order`
export const SALLA_USER_INFO = `/v1/salla/user-info/`

export const POSTMEN_CREATE = `/v1/integrations/postmen/create/`
export const POSTMEN_DELETE = `/v1/integrations/postmen/delete/`
export const POSTMEN_DETAIL = `/v1/integrations/postmen/detail/%s/`
export const POSTMEN_UPDATE = `/v1/integrations/postmen/update/%s/`

export const ZID_CREATE = `/v1/integrations/zid/create/`
export const ZID_DELETE = `/v1/integrations/zid/delete/`
export const ZID_UPDATE = `/v1/integrations/zid/update/%s/`
export const ZID_DETAIL = `/v1/integrations/zid/detail/%s/`
export const ZID_CLIENT_ID_DETAIL = `/v1/integrations/zid/app/detail/`

export const PUBLIC_API_CREATE = `/v1/integrations/public-api/create/`
export const PUBLIC_API_DELETE = `/v1/integrations/public-api/delete/`
export const PUBLIC_API_DETAIL = `/v1/integrations/public-api/detail/%s/`
export const PUBLIC_API_UPDATE = `/v1/integrations/public-api/update/%s/`

export const TRAX_CREATE = `/v1/integrations/trax/create/`
export const TRAX_DELETE = `/v1/integrations/trax/delete/`
export const TRAX_DETAIL = `/v1/integrations/trax/detail/%s/`
export const TRAX_UPDATE = `/v1/integrations/trax/update/%s/`

export const MAGENTO_CREATE = `/v1/integrations/magento/create/`
export const MAGENTO_DELETE = `/v1/integrations/magento/delete/`
export const MAGENTO_DETAIL = `/v1/integrations/magento/detail/%s/`
export const MAGENTO_UPDATE = `/v1/integrations/magento/update/%s/`

export const WOOCOMMERCE_DELETE = `/v1/integrations/woocommerce/delete/`
export const WOOCOMMERCE_DETAIL = `/v1/integrations/woocommerce/detail/%s/`
export const WOOCOMMERCE_UPDATE = `/v1/integrations/woocommerce/update/%s/`
export const INTEGRATION_WOO_COMMERCE_CREATE = `/v1/woocommerce/install/`
export const INTEGRATION_WOO_COMMERCE_RETURN = `/v1/woocommerce/return/`
export const WOOCOMMERCE_RETRY_ORDER = `/v1/integrations/woocommerce/%s/retry-order/`
export const INTEGRATION_SHOPIFY_CALLBACK = `/v1/shopify/callback/`

export const STOREFRONT_CREATE = `/v1/integrations/storefront/create/`
export const STOREFRONT_DELETE = `/v1/integrations/storefront/delete/`
export const STOREFRONT_DETAIL = `/v1/integrations/storefront/detail/%s/`
export const STOREFRONT_UPDATE = `/v1/integrations/storefront/update/%s/`

// PURCHASE_ORDERS
export const PURCHASE_ORDERS_LIST = `/v1/purchase-orders/list/`
export const PURCHASE_ORDERS_CREATE = `/v1/purchase-orders/create/`
export const PURCHASE_ORDERS_CREATE_GATE_PASS = `/v1/gate-pass/create/`
export const PURCHASE_ORDERS_GATE_PASS_LIST = `/v1/gate-pass/list/`
export const PURCHASE_ORDERS_DELETE = `/v1/purchase-orders/delete/`
export const PURCHASE_ORDERS_UPDATE = `/v1/purchase-orders/update/%s/`
export const PURCHASE_ORDER_PROGRESS = `/v1/purchase-orders/%s/progress/`
export const PURCHASE_ORDERS_DETAIL = `/v1/purchase-orders/detail/%s/`
export const PURCHASE_ORDERS_CLOSE = `/v1/purchase-orders/%s/close/`
export const PURCHASE_ORDERS_CANCEL = `/v1/purchase-orders/%s/cancel/`
export const PURCHASE_ORDERS_MARK_AS_PAID = `/v1/purchase-orders/%s/mark-as-paid/`
export const PURCHASE_ORDERS_HISTORY = `/v1/history/purchase/%s/list/`
export const PURCHASE_ORDERS_VARIANTS = `/v1/purchase-orders/variants/list/`
export const PURCHASE_ORDER_TAX_LIST = `/v1/purchase-orders/taxes/list/`
export const PURCHASE_ORDER_WAREHOUSE_LIST = `/v1/purchase-orders/warehouses/list/`
export const PURCHASE_ORDER_EMAIL_TO_SUPPLIER = `/v1/purchase-orders/%s/send-mail/`
export const PURCHASE_ORDER_GENERATE_REPORT = `/v1/purchase-orders/guid/%s/generate-report/`
export const PURCHASE_ORDER_GENERATE_EXCEL_REPORT = `/v1/purchase-orders/generate-excel-report/`
export const PURCHASE_ORDER_GENERATE_PDF_REPORT = `/v1/purchase-orders/generate-pdf-report/`
export const PURCHASE_ORDER_SUPPLIER_LIST = `/v1/purchase-orders/suppliers/variants/list/`
export const PURCHASE_ORDER_FILE_ATTACH = `/v1/purchase-orders/%s/set-attachment/`
export const PURCHASE_ORDER_FILE_ATTACH_REMOVE = `/v1/purchase-orders/%s/remove-attachment/`

// SUPPLIER
export const SUPPLIER_LIST = `/v1/suppliers/list/`
export const SUPPLIER_CREATE = `/v1/suppliers/create/`
export const SUPPLIER_UPDATE = `/v1/suppliers/update/%s/`
export const SUPPLIER_DETAIL = `/v1/suppliers/detail/%s/`
export const SUPPLIER_HISTORY = `/v1/history/supplier/%s/list/`
export const SUPPLIERS_DELETE = `/v1/suppliers/delete/`

// PRODUCT
export const PRODUCT_LIST = `/v1/products/list/`
export const PRODUCT_CREATE = `/v1/products/create/`
export const PRODUCT_UPDATE = `/v1/products/update/%s/`
export const PRODUCT_DETAIL = `/v1/products/detail/%s/`
export const PRODUCT_HISTORY = `/v1/history/product/%s/list/`
export const PRODUCTS_DELETE = `/v1/products/delete/`
export const PRODUCT_IMAGE_LIST = `/v1/products/id/%s/images/list/`
export const PRODUCT_IMAGE_UPDATE = `/v1/products/id/%s/images/add/`
export const PRODUCT_EXPORT = `/v1/reports/product/export/`
export const PRODUCT_SUPPLIER_LIST = '/v1/products/suppliers/list/'

// CATEGORY
export const CATEGORY_LIST = `/v1/categories/list/`
export const CATEGORY_CREATE = `/v1/categories/create/`
export const CATEGORY_UPDATE = `/v1/categories/update/%s/`
export const CATEGORY_DETAIL = `/v1/categories/detail/%s/`
export const CATEGORIES_DELETE = `/v1/categories/delete/`

// BRAND
export const BRAND_LIST = `/v1/brands/list/`
export const BRAND_CREATE = `/v1/brands/create/`
export const BRAND_UPDATE = `/v1/brands/update/%s/`
export const BRAND_DETAIL = `/v1/brands/detail/%s/`
export const BRANDS_DELETE = `/v1/brands/delete/`

// ROLE
export const ROLE_LIST = `/v1/iam/roles/list/`
export const ROLE_CREATE = `/v1/iam/roles/create/`
export const ROLE_UPDATE = `/v1/iam/roles/update/%s/`
export const ROLE_DETAIL = `/v1/iam/roles/detail/%s/`

// PERMISSION
export const PERMISSION_LIST = `/v1/iam/permissions/list/`

// STOCKS
export const AVAILABLE_CONDITIONS = `/v1/stock/conditions/list/`
export const ADJUSTMENT_LIST = `/v1/stock/adjustments/list/`
export const ADJUSTMENT_CREATE = `/v1/stock/adjustments/create/`
export const ADJUSTMENT_CREATE_AND_COMPLETE = `/v1/stock/adjustments/create-and-complete/`
export const ADJUSTMENT_UPDATE = `/v1/stock/adjustments/update/%s/`
export const ADJUSTMENT_UPDATE_AND_COMPLETE = `/v1/stock/adjustments/update-and-complete/%s/`
export const ADJUSTMENT_DETAIL = `/v1/stock/adjustments/detail/%s/`
export const ADJUSTMENT_COMPANY_LIST = `/v1/stock/contract-companies/list/`
export const ADJUSTMENT_COMPLETE = `/v1/stock/adjustments/complete/`
export const ADJUSTMENT_CANCEL = `/v1/stock/adjustments/id/%s/cancel/`
export const ADJUSTMENTS_DELETE = `/v1/stock/adjustments/delete/`
export const ADJUSTMENT_BARCODE_CHECK = `/v1/stock/adjustments/preview/`
export const ADJUSTMENT_BARCODE_UPDATE_CHECK = `/v1/stock/adjustments/preview-update/`
export const ADJUSTMENT_CONDITIONS = `/v1/stock/conditions/list/`
export const ADJUSTMENT_VARIANTS = `/v1/stock/adjustments/variants/list/`
export const ADJUSTMENT_UNIT_CHECK = `/v1/stock/adjustments/validate-unit/`
export const ADJUSTMENT_WAREHOUSE_LIST = `/v1/stock/warehouses/list/`
export const ADJUSTMENT_GENERATE_REPORT = `/v1/stock/adjustments/generate-report/%s/`
export const ADJUSTMENT_HISTORY = `/v1/history/adjustment/%s/list/`
export const ADJUSTMENT_LINE_ITEMS = `/v1/stock/adjustments/%s/line-items/`

export const STOCKS_LOCATION_LIST = `/v1/stock/locations/list/`

// ADJUSTMENT REASON
export const ADJUSTMENT_REASON_LIST = `/v1/stock/adjustment-reasons/list/`
export const ADJUSTMENT_REASON_CREATE = `/v1/stock/adjustment-reasons/create/`
export const ADJUSTMENT_REASON_DETAIL = `/v1/stock/adjustment-reasons/detail/%s/`
export const ADJUSTMENT_REASON_UPDATE = `/v1/stock/adjustment-reasons/update/%s/`
export const ADJUSTMENT_REASON_BULK_REMOVE = `/v1/stock/adjustment-reasons/delete/`

// STOCK_AGING
export const STOCK_AGING_LIST = `/v1/stock/aging/list/`
export const STOCK_AGING_EXPORT = `/v1/reports/stock/aging/`

// PICKLIST
export const PICKLIST_LIST = `/v1/fulfillment/pickings/list/`
export const PICKLIST_DETAIL = `/v1/fulfillment/pickings/detail/%s/`
export const PICKLIST_HISTORY = `/v1/history/picklist/%s/list/`
export const PICKLIST_PROCESS = `/v1/fulfillment/pickings/bulk-move-to-pending/`
export const PICKLIST_PROGRESS = `/v1/fulfillment/pickings/bulk-move-to-progress/`
export const PICKLIST_BATCH = `/v1/fulfillment/pickings/merge/`
export const PICKLIST_DETAIL_PICK = `/v1/fulfillment/pickings/pick/`
export const PICKLIST_DETAIL_PROCESS = `/v1/fulfillment/pickings/move-to-pending/%s/`
export const PICKLIST_DETAIL_PROGRESS = `/v1/fulfillment/pickings/move-to-progress/%s/`
export const PICKLIST_DETAIL_COMPLETE = `/v1/fulfillment/pickings/complete/`
export const PICKLIST_SET_PICKER = `/v1/fulfillment/pickings/set-picker/`
export const PICKLIST_SET_PICKER_BULK = `/v1/fulfillment/pickings/bulk-set-pickers/`
export const PICKLIST_SKIP_PROCESS = `/v1/fulfillment/pickings/skip/%s/`
export const PICKLIST_PDF_REPORT = `/v1/fulfillment/pickings/generate-pdf/`
export const PICKLIST_UNIT_ALTER = `/v1/fulfillment/pickings/alter-line-item/`
export const PICKLIST_CANCEL = `/v1/fulfillment/pickings/cancel/%s/`

// PUTAWAY
export const V1_PUTAWAY_USERS_LIST = `/v1/stock/putaways/assign-user/list/`
export const V1_PUTAWAY_ASSIGN_TO = `/v1/stock/putaways/assign-user/%s/`
export const V1_PUTAWAY_COMPLETE = `/v1/stock/putaways/complete/%s/`
export const V1_PUTAWAY_CREATE = `/v1/stock/putaways/create/`
export const V1_PUTAWAY_CREATE_PROGRESS = `/v1/stock/putaways/create-progress/`
export const V1_PUTAWAY_DETAIL = `/v1/stock/putaways/detail/%s/`
export const V1_PUTAWAY_LINE_ITEMS = `/v1/stock/putaways/detail/%s/line-items/`
export const V1_PUTAWAY_LIST = `/v1/stock/putaways/list/`
export const V1_PUTAWAY_LOCATION = `/v1/stock/putaways/location/%s/%s/`
export const V1_PUTAWAY_ITEM_PUT = `/v1/stock/putaways/put/%s/`
export const V1_PUTAWAY_LOCATION_SUGGEST = `/v1/stock/putaways/suggest-locations/`
export const V1_PUTAWAY_PROGRESS = `/v1/stock/putaways/progress/%s/`

export const V2_PUTAWAY_HISTORY = `/v1/history/putaway/%s/list/`

// USER
export const USER_LIST = `/v1/iam/users/list/`
export const USER_CREATE = `/v1/iam/users/create/`
export const USER_UPDATE = `/v1/iam/users/update/%s/`
export const USER_DETAIL = `/v1/iam/users/detail/%s/`
export const USER_HISTORY = `/v1/history/user-history/%s/`
export const USERS_DELETE = `/v1/iam/users/delete/`

// REGION
export const REGION_LIST = `/v1/regions/list/`

// VARIANT
export const VARIANT_LIST = `/v1/variants/list/`
export const VARIANT_DELETE = `/v1/variants/delete/`
export const VARIANT_COMPANIES_LIST = `/v1/variants/companies/list/`
export const VARIANT_CONTRACT_COMPANIES_LIST = `/v1/orders/contract-companies/list/`
export const VARIANT_UPDATE = `/v1/variants/update/%s/`
export const VARIANT_DETAIL = `/v1/variants/detail/%s/`
export const VARIANT_STOCK_LIST = `/v1/variants/id/%s/stocks/list/`
export const VARIANT_LISTING_CREATE = `/v1/listings/variants/add/`
export const VARIANT_CHANNEL_LIST = `/v1/listings/channels/list/`
export const VARIANT_HISTORY = `/v1/history/product-variant/%s/list/`
export const CONDITION_PRICING_LIST = `/v1/variants/id/%s/condition-prices/list/`
export const CONDITION_PRICING_UPDATE = `/v1/variants/id/%s/condition-prices/update/`

// STOCK SYNC
export const STOCK_SYNC = `/v1/stock/sync-stock/`

// GENERAL
export const WAREHOUSE_LIST = '/v1/stock/warehouses/list/'
export const WAREHOUSE_CREATE = `/v1/stock/warehouses/create/`
export const WAREHOUSE_UPDATE = `/v1/stock/warehouses/update/%s/`
export const WAREHOUSE_DETAIL = `/v1/stock/warehouses/detail/%s/`
export const WAREHOUSES_DELETE = `/v1/stock/warehouses/delete/%s/`

// AREA
export const AREA_LIST = '/v1/stock/areas/list/'
export const AREA_CREATE = `/v1/stock/areas/create/`
export const AREA_DETAIL = `/v1/stock/areas/detail/%s/`
export const AREA_UPDATE = `/v1/stock/areas/update/%s/`
export const AREA_DELETE = `/v1/stock/areas/delete/%s/`

// ZONE
export const ZONE_LIST = '/v1/stock/zones/list/'
export const ZONE_CREATE = `/v1/stock/zones/create/`
export const ZONE_DETAIL = `/v1/stock/zones/detail/%s/`
export const ZONE_UPDATE = `/v1/stock/zones/update/%s/`
export const ZONE_DELETE = `/v1/stock/zones/delete/%s/`

// LOCATION
export const LOCATION_LIST = '/v1/stock/locations/list/'
export const LOCATION_CREATE = `/v1/stock/locations/create/`
export const LOCATION_DETAIL = `/v1/stock/locations/detail/%s/`
export const LOCATION_UPDATE = `/v1/stock/locations/update/%s/`
export const LOCATION_CONTENT = `/v1/stock/locations/guid/%s/content/detail/`
export const LOCATIONS_DELETE = `/v1/stock/locations/delete/`
export const LOCATION_VISUALIZATION_LIST = `/v1/stock/visualizations/locations/list/`

// CONTAINER
export const CONTAINER_LIST = '/v1/containers/list/'
export const CONTAINER_CREATE = `/v1/containers/create/`
export const CONTAINER_BULK_CREATE = `/v1/containers/bulk-create/`
export const CONTAINER_DETAIL = `/v1/containers/detail/%s/`
export const CONTAINER_CONTENT = `/v1/containers/%s/content/`
export const CONTAINER_DELETE = `/v1/containers/delete/%s/`

// CONTAINER_TYPE
export const CONTAINER_TYPE_LIST = '/v1/stock/container-types/list/'
export const CONTAINER_TYPE_CREATE = `/v1/stock/container-types/create/`
export const CONTAINER_TYPE_DETAIL = `/v1/stock/container-types/detail/%s/`
export const CONTAINER_TYPE_UPDATE = `/v1/stock/container-types/update/%s/`
export const CONTAINER_TYPE_DELETE = `/v1/stock/container-types/delete/%s/`
export const CONTAINER_TYPE_BULK_DELETE = `/v1/stock/container-types/delete/`

// SEARCH
export const SEARCH = '/v1/search/'

// CONDITION
export const CONDITION_LIST = `/v1/catalog/conditions/list/`
export const CONDITION_CREATE = `/v1/catalog/conditions/create/`
export const CONDITION_UPDATE = `/v1/catalog/conditions/update/%s/`
export const CONDITION_DETAIL = `/v1/catalog/conditions/detail/%s/`

// RECEIVE
export const RECEIVE_LIST = `/v1/receives/receipts/list/`
export const RECEIVE_LIST_COMPLETE = `/v1/receives/receipts/complete/`
export const RECEIVE_HISTORY = `/v1/history/receive/%s/list/`
export const RECEIVE_COMPLETE = `/v1/receives/receipts/guid/%s/complete/`

export const RECEIVE_PURCHASE_ORDERS = `/v1/receives/purchase-orders/detail/%s/`
export const RECEIVE_PURCHASE_ORDERS_VARIANT_DETAIL = '/v1/receives/purchase-orders/%s/variant/detail/%s/'
export const RECEIVE_PURCHASE_RECEIVE = `/v1/receives/purchase-orders/guid/%s/receive/`
export const RECEIVE_PURCHASE_SCAN_RECEIVE = `/v1/receives/purchase-orders/guid/%s/receive-by-device/`

export const RECEIVE_DIMENSIONS_UPDATE = `/v1/receives/variants/%s/set-dimensions/`

export const RECEIVE_RETURN_SCAN_RECEIVE = `/v1/receives/returns/%s/receive-by-device/`
export const RECEIVE_RETURN_ORDERS = `/v1/receives/returns/detail/%s/`
export const RECEIVE_RETURNS_VARIANT_DETAIL = '/v1/receives/returns/%s/variant/detail/%s/'

export const RECEIVE_VARIANT_BY_BARCODE = `/v1/receives/%s/variants/%s/detail/`

export const RECEIVE_STOCK_TRANSFER_ORDERS = `/v1/receives/stock-transfers/detail/%s/`
export const RECEIVE_STOCK_SCAN_RECEIVE = `/v1/receives/stock-transfer/%s/receive-by-device/`
export const RECEIVE_STOCK_VARIANT_DETAIL = '/v1/receives/stock-transfers/%s/variant/detail/%s/'

export const TRANSFER_RECEIVE_DETAIL = `/v1/receives/stock-transfers/detail/%s/`
export const TRANSFER_RECEIVE_UPDATE = `/v1/receives/stock-transfers/receive/`
export const TRANSFER_RECEIVE_UNIT_CHECK = `/v1/receives/stock-transfers/validate-unit/`

export const RECEIVE_RETURN_DETAIL = `/v1/receives/returns/detail/%s/`
export const RECEIVE_RETURN_UPDATE = `/v1/receives/returns/guid/%s/receive/`
export const RECEIVE_RETURN_LOCATION_LIST = `/v1/receives/locations/list/`
export const RETURN_WAREHOUSE_LIST = `/v1/returns/warehouses/list/`

// PACKING
export const PACKING_LIST = `/v1/packings/list/`
export const PACKING_DETAIL = `/v1/packings/detail/%s/`
export const PACKING_HISTORY = `/v1/history/packing/%s/list/`
export const PACKING_PROCESS = `/v1/packings/process/%s/`
export const PACKING_PROCESS_BULK = `/v1/packings/process/`
export const PACKING_UPDATE = `/v1/packings/update/%s/`
export const PACKING_COMPLETE = `/v1/packings/complete/`
export const PACKING_INTEGRATIONS = '/v1/packings/integrations/list/'
export const PACKING_BY_CONTAINER_NUMBER = '/v1/packings/list-by-container-number/%s/'
export const PACKING_JOB_CREATE = '/v1/packings/set/packer/'
export const PACKING_OPTIMAL_CONTAINER_LIST = `/v1/packings/%s/optimal-containers/`
export const PACKING_LOCATION_LIST = `/v1/packings/locations/list/`
export const PACKING_SKIP_PROCESS = `/v1/packings/skip/%s/`
export const PACKING_SLIP_PDF_REPORT = `/v1/packings/generate-slip/%s/`

// FILE
export const FILE_UPLOAD = `/v1/files/upload/`
export const PRODUCT_FILE_VALIDATE = `/v1/bulk-upload/products/validate/`
export const PRODUCT_FILE_UPLOAD = `/v1/bulk-upload/products/upload/`
export const PRODUCT_FILE_UPDATE_UPLOAD = `/v1/bulk-upload/products/update-upload/`
export const PRODUCT_FILE_UPDATE_VALIDATE = `/v1/bulk-upload/products/update-validate/`
export const WAREHOUSE_FILE_VALIDATE = `/v1/bulk-upload/warehouses/validate/`
export const WAREHOUSE_FILE_UPLOAD = `/v1/bulk-upload/warehouses/upload/`
export const SALE_ORDER_FILE_VALIDATE = `/v1/bulk-upload/orders/validate/`
export const SALE_ORDER_FILE_UPLOAD = `/v1/bulk-upload/orders/upload/`
export const ADJUSTMENT_FILE_UPLOAD = `/v1/bulk-upload/adjustments/upload/`
export const ADJUSTMENT_FILE_VALIDATE = `/v1/bulk-upload/adjustments/validate/`
export const PURCHASE_ORDER_FILE_UPLOAD = `/v1/bulk-upload/purchase-orders/upload/`
export const PURCHASE_ORDER_FILE_VALIDATE = `/v1/bulk-upload/purchase-orders/validate/`
export const PCIKLIST_FILE_UPLOAD = `/v1/bulk-upload/pickings/upload/`
export const PCIKLIST_FILE_VALIDATE = `/v1/bulk-upload/pickings/validate/`

// TRANSFER
export const TRANSFER_LIST = `/v1/stock-transfers/list/`
export const TRANSFER_CREATE = `/v1/stock-transfers/create/`
export const TRANSFER_CREATE_AND_PROCESS = `/v1/stock-transfers/create-and-process/`
export const TRANSFER_UPDATE = `/v1/stock-transfers/update/%s/`
export const TRANSFER_UPDATE_AND_PROCESS = `/v1/stock-transfers/update-and-process/%s/`
export const TRANSFER_DETAIL = `/v1/stock-transfers/detail/%s/`
export const TRANSFERS_DELETE = `/v1/stock-transfers/delete/`
export const TRANSFER_VARIANTS = `/v1/stock-transfers/variants/list/`
export const TRANSFER_LIST_PROCESS = `/v1/stock-transfers/process/`
export const TRANSFER_DETAIL_PROCESS = `/v1/stock-transfers/guid/%s/process/`
export const TRANSFER_LIST_COMPLETE = `/v1/stock-transfers/complete/`
export const TRANSFER_DETAIL_COMPLETE = `/v1/stock-transfers/guid/%s/complete/`
export const TRANSFER_LIST_CANCEL = `/v1/stock-transfers/cancel/`
export const TRANSFER_UNITS = `/v1/stock-transfers/available-units/list/`
export const TRANSFER_TO_WAREHOUSE = `/v1/stock-transfers/warehouses/list/`
export const TRANSFER_HISTORY = `/v1/history/stock-transfer/%s/list/`

// REORDER
export const REORDER_LIST = `/v1/stock/low-stock/list/`

// LISTING
export const LISTING_LIST = `/v1/listings/list/`
export const LISTING_DETAIL = `/v1/listings/products/%s/detail/`
export const LISTING_UPDATE = `/v1/listings/products/%s/update/`
export const LISTING_PUBLISH = `/v1/listings/publish/`
export const LISTING_REJECT = `/v1/listings/reject/`
export const LISTING_UNPUBLISH = `/v1/listings/unpublish/`
export const LISTING_PAUSE = `/v1/listings/pause/`
export const LISTING_BRAND_LIST = `/v1/listings/brands/list/`
export const LISTING_CATEGORY_LIST = `/v1/listings/categories/list/`

// UNIT_NUMBERS
export const UNIT_NUMBER_LIST = `/v1/units/list/`
export const UNIT_HISTORY = `/v1/units/history/list/`
export const UNIT_COMPANY_LIST = `/v1/stock/contract-companies/list/`
export const UNIT_EXPORT = `/v1/reports/stock/unit/`
export const UNIT_BARCODE_GENERATE = `/v1/units/bulk-generate-barcodes/`

// RETAILER UNIT REPORT
export const RETAILER_UNIT_REPORT = `/v1/reports/stock/retailer-units/generate-report/`

// LOCATION UNIT REPORT
export const LOCATION_UNIT_REPORT = `/v1/reports/stock/generate-locationwise-unit-report/`

// SHIPMENT
export const SHIPMENT_LIST = `/v1/shipments/list/`
export const SHIPMENT_UPDATE = `/v1/shipments/update/%s/`
export const SHIPMENT_OUTBOUND_GATE_PASS = `/v1/shipments/%s/generate-gate-pass/`
export const SHIPMENT_DETAIL = `/v1/shipments/detail/%s/`
export const SHIPMENT_GENERATE_COMMERCIAL_INVOICE = `/v1/shipments/commercial-invoice/detail/%s/`
export const SHIPMENT_PROCESS = `/v1/shipments/id/%s/process/`
export const SHIPMENT_SAVE_PROCESS = `/v1/shipments/id/%s/save-and-process/`
export const SHIPMENT_REPORT_GENERATE = `/v1/shipments/id/%s/generate-report/`
export const SHIPMENT_HISTORY = `/v1/history/shipment/%s/list/`
export const SHIPMENT_INTEGRATIONS = `/v1/shipments/integrations/list/`
export const SHIPMENT_SERVICES = `/v1/integrations/carriers/%s/services/`
export const SHIPMENT_AWB_DETAIL = `/v1/integrations/carriers/%s/air-waybill/`
export const SHIPMENT_RECALCULATE = `/v1/shipments/%s/recalculate/`
export const SHIPMENT_SET_STATUS = `/v1/shipments/%s/set-status/`
export const SHIPMENT_LIST_SET_STATUS = `/v1/shipments/set-status-bulk/`
export const SHIPMENT_CONSOLIDATED_GATEPASS_GENERATE = `/v1/shipments/generate-consolidated-gate-pass/`
export const SHIPMENT_UPDATE_CONTAINER = `/v1/shipments/%s/container/update/`
export const SHIPMENT_EXCEL_EXPORT = `/v1/reports/shipment/export/`
export const SHIPMENT_CANCEL_CARRIER = `/v1/shipments/%s/cancel-carrier/`
export const SHIPMENT_ASL_UNIT_SCAN = `/v1/shipments/%s/set-unit-asl-numbers/`
export const SHIPMENT_INTEGRATION_CITIES = `/v1/integrations/carriers/%s/cities/`
export const SHIPMENT_INTEGRATION_SET_COURIER = `/v1/shipments/%s/set-courier/`

// ACCOUNT
export const ACCOUNT_DETAIL = `/v1/iam/accounts/detail/`
export const ACCOUNT_UPDATE = `/v1/iam/accounts/update/`

// CONTRACT
export const CONTRACT_LIST = `/v1/iam/contracts/list/`
export const CONTRACT_CREATE = `/v1/iam/contracts/create/`
export const CONTRACT_CREATE_WITH_RETAILER = `/v1/iam/contracts/create-with-retailer/`
export const CONTRACT_UPDATE = `/v1/iam/contracts/update/%s/`
export const CONTRACT_DETAIL = `/v1/iam/contracts/detail/%s/`
export const CONTRACT_DECIDE = `/v1/iam/contracts/decide/`
export const CONTRACT_BILLING_LIST = `/v1/iam/companies/billing-profiles/list/`
export const SHIPOX_CUSTOMERS = `/v1/integrations/guid/%s/shipox-customers/list/`
export const CONTRACT_INVOICE_LIST = `/v1/billings/%s/bill-invoice/list/`
export const CONTRACT_INVOICE_DETAIL = `/v1/billings/:contractGuid/bill-invoice/detail/:guid`
export const CONTRACT_INVOICE_GENERATE = `/v1/billings/:contractGuid/bill-invoice/generate/:guid`

// BARCODE
export const BARCODE_GENERATE = `/v1/unit-numbers/generate/`

// MOVEMENT
export const STOCK_AREA_LIST = `/v1/stock/areas/list/`
export const STOCK_ZONE_LIST = `/v1/stock/zones/list/`
export const MOVEMENT_CREATE_AND_COMPLETE = `/v1/stock/movements/create-and-complete/`
export const MOVEMENT_CREATE = `/v1/stock/movements/create/`
export const MOVEMENTS_DELETE = `/v1/stock/movements/delete/`
export const MOVEMENT_DETAIL = `/v1/stock/movements/detail/%s/`
export const MOVEMENT_CANCEL = `/v1/stock/movements/id/%s/cancel/`
export const MOVEMENT_COMPLETE = `/v1/stock/movements/id/%s/complete/`
export const MOVEMENT_LIST = `/v1/stock/movements/list/`
export const MOVEMENT_UPDATE_AND_COMPLETE = `/v1/stock/movements/update-and-complete/%s/`
export const MOVEMENT_UPDATE = `/v1/stock/movements/update/%s/`
export const CONTAINER_BY_NUMBER = `/v1/containers/detail-by-number/%s/`
export const MOVEMENT_HISTORY = `/v1/history/movement/%s/list/`
export const MOVEMENT_UPLOAD = `/v1/bulk-upload/movements/upload/`
export const MOVEMENT_FILE_VALIDATE = `/v1/bulk-upload/movements/validate/`
export const MOVEMENT_LINE_ITEMS = `/v1/stock/movements/%s/line-items/`

// RETURN
export const SALE_RETURN_LIST = `/v1/returns/customer-returns/list/`
export const SALE_RETURN_CREATE = `/v1/returns/customer-returns/create/`
export const SALE_RETURN_DETAIL = `/v1/returns/customer-returns/detail/%s/`
export const SALE_RETURN_PROCESS = `/v1/returns/customer-returns/process/%s/`
export const SALE_RETURN_UPDATE = `/v1/returns/customer-returns/update/%s/`
export const SALE_RETURN_CANCEL = `/v1/returns/customer-returns/cancel/`
export const SALE_RETURN_HISTORY = `/v1/history/return/%s/list/`
export const SUPPLIER_RETURN_LIST = `/v1/returns/supplier-returns/list/`
export const SUPPLIER_RETURN_CREATE = `/v1/returns/supplier-returns/create/`
export const SUPPLIER_RETURN_DETAIL = `/v1/returns/supplier-returns/detail/%s/`
export const SUPPLIER_RETURN_UPDATE = `/v1/returns/supplier-returns/update/%s/`
export const SUPPLIER_RETURN_ALLOCATE = `/v1/returns/supplier-returns/%s/allocate/`
export const SUPPLIER_RETURN_PROCESS = `/v1/returns/supplier-returns/%s/process/`
export const SUPPLIER_RETURN_UNITS = `/v1/returns/units/list/`
export const SUPPLIER_RETURN_SUPPLIERS = `/v1/returns/suppliers/list/`
export const SUPPLIER_RETURN_HISTORY = `/v1/history/return/%s/list/`
export const RETURN_CREATE_DETAIL = `/v1/returns/orders/detail/%s/`
export const RETURN_VARIANTS = `/v1/returns/orders/variants/list/`
export const RETURN_CARRIERS = `/v1/returns/carriers/list/`
export const RETURN_INTEGRATION_CHECK = `/v1/integrations/check-return/`
export const RETURN_SERVICES_CHECK = `/v1/integrations/guid/%s/shipox-services/list-return/`

export const RETURN_CONDITIONS = `/v1/returns/orders/conditions/list/`

// AUTOMATION_RULES
export const AUTOMATION_RULE_LIST = `/v1/automations/list/`
export const AUTOMATION_RULE_CREATE = `/v1/automations/create/`
export const AUTOMATION_RULE_UPDATE = `/v1/automations/update/%s/`
export const AUTOMATION_RULE_DETAIL = `/v1/automations/detail/%s/`
export const AUTOMATION_RULE_DELETE = `/v1/automations/delete/`
export const AUTOMATION_RULE_USER_LIST = `/v1/automations/users/list/`
export const AUTOMATION_RULE_LOCATION_LIST = `/v1/automations/locations/list/`
export const AUTOMATION_RULE_ZONE_LIST = `/v1/automations/zones/list/`
export const AUTOMATION_RULE_WAREHOUSE_LIST = `/v1/automations/contract-warehouses/list/`
export const AUTOMATION_RULE_COMPANY_LIST = `/v1/automations/companies/list/`
export const AUTOMATION_RULE_CHANNEL_LIST = `/v1/automations/channels/list/`
export const AUTOMATION_RULE_CONDITION_LIST = `/v1/automations/conditions/list/`
export const AUTOMATION_RULE_CATEGORY_LIST = `/v1/automations/categories/list/`
export const AUTOMATION_RULE_VARIANT_LIST = `/v1/automations/variants/list/`

// NOTIFICATIONS
export const NOTIFICATION_LIST = `/v1/notifications/list/`
export const UNREAD_NOTIFICATION_COUNT = `/v1/notifications/count-unread/`
export const NOTIFICATIONS_MARK_READ = `/v1/notifications/mark-as-read/`

// BILLING PROFILE
export const BILLING_PROFILE_LIST = `/v1/billings/profiles/list/`
export const BILLING_PROFILE_DETAIL = `/v1/billings/profiles/detail/%s/`
export const BILLING_PROFILE_CREATE = `/v1/billings/profiles/create/`
export const BILLING_PROFILE_UPDATE = `/v1/billings/profiles/update/%s/`
export const BILLING_PROFILE_WAREHOUSE_LIST = `/v1/billings/warehouses/list/`
export const BILLING_PROFILE_ZONE_LIST = `/v1/billings/zones/list/`
export const BILLING_PROFILE_AREA_LIST = `/v1/billings/areas/list/`
export const BILLING_PROFILE_PACKING_CONTAINER_TYPE = `/v1/billings/container-types/list/`

// BILLING
export const BILLING_DETAIL = `/v1/finance/tpl-invoices/detail/%s/`
export const BILLING_LIST = `/v1/finance/tpl-invoices/list/`
export const BILLING_PAYMENT_DETAIL = `/v1/finance/tpl-invoices/payment/%s/`

// TPL BILLING
export const TPL_BILLING_LIST = `/v1/billings/list/`
export const TPL_BILLING_GENERATE_EXCEL = `/v1/billings/generate-excel/`
export const TPL_BILLING_DETAIL = `/v1/billings/detail/%s/`
export const TPL_BILLING_STORAGE_DETAIL = `/v1/billings/storage-bills/detail/%s/`
export const TPL_BILLING_FULFILLMENT_DETAIL = `/v1/billings/fulfillment-bills/detail/%s/`
export const TPL_BILLING_PACKING_DETAIL = `/v1/billings/packing-bills/detail/%s/`
export const TPL_BILLING_ADHOC_DETAIL = `/v1/billings/adhoc-detail/%s/`
export const TPL_BILLING_RECEIVING_DETAIL = `/v1/billings/receiving-detail/%s/`
export const TPL_BILLING_MARK_AS_PAID = `/v1/billings/guid/%s/mark-as-paid/`
export const TPL_BILLING_CUSTOMER_LIST = `/v1/billings/customers/list/`
export const TPL_BILLING_EXCEL_GENERATE = `/v1/billings/:contractGuid/bill-invoice/generate-excel/:guid/`
export const TPL_BILLING_INVOICE_MARK_AS_PAID = `/v1/billings/:contractGuid/bill-invoice/mark-as-paid/:guid/`

// INVENTORY LOG
export const INVENTORY_LOG_LIST = `/v1/stock/inventory-logs/list/`
export const INVENTORY_LOG_REPORT = `/v1/reports/stock/inventory/`

// STOCK COUNT
export const STOCK_COUNT_AREA_LIST = `/v1/stock/stock-counts/areas/list/`
export const STOCK_COUNT_CREATE_AND_PROCESS = `/v1/stock/stock-counts/create-and-process/`
export const STOCK_COUNT_CREATE = `/v1/stock/stock-counts/create/`
export const STOCK_COUNT_DETAIL = `/v1/stock/stock-counts/detail/%s/`
export const STOCK_COUNT_LIST = `/v1/stock/stock-counts/list/`
export const STOCK_COUNT_LOCATION_LIST = `/v1/stock/stock-counts/locations/list/`
export const STOCK_COUNT_WAREHOUSE_LIST = `/v1/stock/stock-counts/warehouses/list/`
export const STOCK_COUNT_ZONE_LIST = `/v1/stock/stock-counts/zones/list/`
export const STOCK_COUNT_CANCEL = `/v1/stock/stock-counts/%s/cancel/`
export const STOCK_COUNT_PROCESS = `/v1/stock/stock-counts/%s/process/`

// BUNDLE
export const BUNDLE_LIST = `/v1/bundles/list/`
export const BUNDLE_CREATE = `/v1/bundles/create/`
export const BUNDLE_UPDATE = `/v1/bundles/update/%s/`
export const BUNDLE_DETAIL = `/v1/bundles/detail/%s/`
export const BUNDLES_DELETE = `/v1/bundles/delete/`
export const BUNDLE_VARIANT_LIST = `/v1/bundles/variants/list/`
export const BUNDLE_CONDITION_LIST = `/v1/bundles/variants/guid/%s/conditions/list/`

// INVOICE
export const ORDER_INVOICE_LIST = `/v1/order/invoices/list/`
export const ORDER_INVOICE_GENERATE = `/v1/orders/guid/%s/generate-invoice/`
export const ORDER_INVOICE_DETAIL = `/v1/order/invoices/detail/:%/`
export const ORDER_INVOICES_DELETE = `/v1/order/invoices/delete/`
export const ORDER_HEALTH_CARE_INVOICE_GENERATE = `/v1/orders/generate-healthcare-invoice/%s/`

// PURCHASE ORDER INVOICE
export const PURCHASE_ORDER_INVOICE_GENERATE = `/v1/purchase-orders/guid/%s/generate-invoice/`

// TAX
export const TAX_LIST = `/v1/catalog/taxes/list/`
export const TAX_CREATE = `/v1/catalog/taxes/create/`
export const TAX_DETAIL = `/v1/catalog/taxes/detail/%s/`
export const TAX_UPDATE = `/v1/catalog/taxes/update/%s/`
export const TAXES_DELETE = `/v1/catalog/taxes/delete/`
export const TAX_REMOVE = `/v1/catalog/taxes/delete/%s/`

// DELIVERY METHOD
export const DELIVERY_METHOD_LIST = `/v1/shipments/delivery-method/list/`
export const DELIVERY_METHOD_CREATE = `/v1/shipments/delivery-method/create/`
export const DELIVERY_METHOD_DETAIL = `/v1/shipments/delivery-method/detail/%s/`
export const DELIVERY_METHOD_UPDATE = `/v1/shipments/delivery-method/update/%s/`
export const DELIVERY_METHODS_DELETE = `/v1/shipments/delivery-method/delete/`

// CURRENCY RATE

export const CURRENCY_RATE_LIST = `/v1/iam/currency-rates/list/`
export const CURRENCY_RATE_CREATE = `/v1/iam/currency-rates/create/`
export const CURRENCY_RATE_DETAIL = `/v1/iam/currency-rates/detail/%s/`

// TEMPLATE

export const TEMPLATE_LIST = `/v1/pdf-templates/list/`
export const TEMPLATE_DETAIL = `/v1/pdf-templates/detail/%s/`
export const TEMPLATE_UPDATE = `/v1/pdf-templates/update/%s/`
export const TEMPLATE_PREVIEW = `/v1/pdf-templates/preview/`
