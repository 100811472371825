import { AuthLayout } from '~/components/Layouts'
import * as ROUTES from '~/constants/routes'

import { ConfigurationContainer } from './containers/ConfigurationContainers'
import {
  AutomationRuleCreateContainer,
  AutomationRuleListContainer,
  AutomationRuleUpdateContainer
} from './containers/AutomationRuleContainers'
import {
  ConditionCreateContainer,
  ConditionListContainer,
  ConditionUpdateContainer
} from './containers/ConditionContaners'
import {
  AdjustmentReasonCreateContainer,
  AdjustmentReasonListContainer,
  AdjustmentReasonUpdateContainer
} from './containers/AdjustmentReasonContainers'
import {
  ContainerTypeCreateContainer,
  ContainerTypeListContainer,
  ContainerTypeUpdateContainer
} from './containers/ContainerTypeContainers'
import {
  ContractCreateContainer,
  ContractListContainer,
  ContractUpdateContainer,
  ContractInvoiceDetailContainer,
  ContractCreateWithRetailerContainer
} from './containers/ContractContainers'
import {
  BillingProfileCreateContainer,
  BillingProfileListContainer,
  BillingProfileUpdateContainer
} from './containers/BillingProfileContainers'
import { TaxCreateContainer, TaxListContainer, TaxUpdateContainer } from './containers/TaxContaners'
import {
  DeliveryMethodCreateContainer,
  DeliveryMethodListContainer,
  DeliveryMethodUpdateContainer
} from './containers/DeliveryMethodContainers'
import {
  CurrencyRateCreateContainer,
  CurrencyRateListContainer,
  CurrencyRateUpdateContainer
} from './containers/CurrencyRateContainers'
import { TemplateListContainer, TemplateUpdateContainer } from './containers/TemplatesContainers'

const currencyRate = () => [
  {
    layout: AuthLayout,
    path: ROUTES.CURRENCY_RATE_LIST_PATH,
    component: CurrencyRateListContainer
  },
  {
    layout: AuthLayout,
    path: ROUTES.CURRENCY_RATE_CREATE_PATH,
    component: CurrencyRateCreateContainer
  },
  {
    layout: AuthLayout,
    path: ROUTES.CURRENCY_RATE_UPDATE_PATH,
    component: CurrencyRateUpdateContainer
  }
]
const condition = () => [
  {
    layout: AuthLayout,
    path: ROUTES.CONDITION_LIST_PATH,
    component: ConditionListContainer
  },
  {
    layout: AuthLayout,
    path: ROUTES.CONDITION_CREATE_PATH,
    component: ConditionCreateContainer
  },
  {
    layout: AuthLayout,
    path: ROUTES.CONDITION_UPDATE_PATH,
    component: ConditionUpdateContainer
  }
]

const adjustmentReason = () => [
  {
    layout: AuthLayout,
    path: ROUTES.ADJUSTMENT_REASON_LIST_PATH,
    component: AdjustmentReasonListContainer
  },
  {
    layout: AuthLayout,
    path: ROUTES.ADJUSTMENT_REASON_CREATE_PATH,
    component: AdjustmentReasonCreateContainer
  },
  {
    layout: AuthLayout,
    path: ROUTES.ADJUSTMENT_REASON_UPDATE_PATH,
    component: AdjustmentReasonUpdateContainer
  }
]

const containerType = () => [
  {
    layout: AuthLayout,
    path: ROUTES.CONTAINER_TYPE_LIST_PATH,
    component: ContainerTypeListContainer
  },
  {
    layout: AuthLayout,
    path: ROUTES.CONTAINER_TYPE_CREATE_URL,
    component: ContainerTypeCreateContainer
  },
  {
    layout: AuthLayout,
    path: ROUTES.CONTAINER_TYPE_UPDATE_PATH,
    component: ContainerTypeUpdateContainer
  }
]

const contract = () => [
  {
    layout: AuthLayout,
    path: ROUTES.CONTRACT_LIST_PATH,
    component: ContractListContainer
  },
  {
    layout: AuthLayout,
    path: ROUTES.CONTRACT_CREATE_PATH,
    component: ContractCreateContainer
  },
  {
    layout: AuthLayout,
    path: ROUTES.CONTRACT_CREATE_WITH_RETAILER_PATH,
    component: ContractCreateWithRetailerContainer
  },
  {
    layout: AuthLayout,
    path: ROUTES.CONTRACT_UPDATE_PATH,
    component: ContractUpdateContainer
  },
  {
    layout: AuthLayout,
    path: ROUTES.CONTRACT_INVOICE_DETAIL_PATH,
    component: ContractInvoiceDetailContainer
  }
]

const automation = () => [
  {
    layout: AuthLayout,
    component: AutomationRuleCreateContainer,
    path: ROUTES.AUTOMATION_CREATE_PATH
  },
  {
    layout: AuthLayout,
    component: AutomationRuleUpdateContainer,
    path: ROUTES.AUTOMATION_UPDATE_PATH
  },
  {
    layout: AuthLayout,
    component: AutomationRuleListContainer,
    path: ROUTES.AUTOMATION_LIST_PATH
  }
]

const billingProfile = () => [
  {
    layout: AuthLayout,
    component: BillingProfileListContainer,
    path: ROUTES.BILLING_PROFILE_LIST_PATH
  },
  {
    layout: AuthLayout,
    component: BillingProfileCreateContainer,
    path: ROUTES.BILLING_PROFILE_CREATE_PATH
  },
  {
    layout: AuthLayout,
    component: BillingProfileUpdateContainer,
    path: ROUTES.BILLING_PROFILE_UPDATE_PATH
  }
]

const tax = () => [
  {
    layout: AuthLayout,
    component: TaxListContainer,
    path: ROUTES.TAX_LIST_PATH
  },
  {
    layout: AuthLayout,
    component: TaxCreateContainer,
    path: ROUTES.TAX_CREATE_PATH
  },
  {
    layout: AuthLayout,
    component: TaxUpdateContainer,
    path: ROUTES.TAX_UPDATE_PATH
  }
]

const deliveryMethod = () => [
  {
    layout: AuthLayout,
    component: DeliveryMethodListContainer,
    path: ROUTES.DELIVERY_METHOD_LIST_PATH
  },
  {
    layout: AuthLayout,
    component: DeliveryMethodCreateContainer,
    path: ROUTES.DELIVERY_METHOD_CREATE_PATH
  },
  {
    layout: AuthLayout,
    component: DeliveryMethodUpdateContainer,
    path: ROUTES.DELIVERY_METHOD_UPDATE_PATH
  }
]

const templates = () => [
  {
    layout: AuthLayout,
    component: TemplateListContainer,
    path: ROUTES.TEMPLATE_LIST_PATH
  },
  {
    layout: AuthLayout,
    component: TemplateUpdateContainer,
    path: ROUTES.TEMPLATE_UPDATE_PATH
  }
]
export default () => [
  {
    layout: AuthLayout,
    path: ROUTES.CONFIGURATION_MENU_PATH,
    component: ConfigurationContainer
  },
  ...currencyRate(),
  ...condition(),
  ...adjustmentReason(),
  ...containerType(),
  ...contract(),
  ...automation(),
  ...billingProfile(),
  ...tax(),
  ...templates(),
  ...deliveryMethod()
]
