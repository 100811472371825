import React from 'react'
import { path, pathOr, propOr } from 'ramda'
import PropTypes from 'prop-types'
import { sprintf } from 'sprintf-js'
import { useField } from 'react-final-form'

import * as API from '~/constants/api'

import MultiSearchChipField from '../Fields/MultiSearchChipField'

function CarrierCitySearchField ({ onChange, ...props }) {
  const ruleField = useField('rule')
  const rule = ruleField.input.value
  const rules = propOr([], 'rules', rule)

  const actionsField = useField('actions')
  const actionsValue = actionsField.input.value
  const serviceId = path(['0', 'assignToCarrier', 'serviceType', 'id'], actionsValue)

  const carrierRule = rules.find(item => item.field === 'carrier')
  const methodGuid = pathOr('', ['value', 'guid'], carrierRule)

  const citiesApi = methodGuid && sprintf(API.SHIPMENT_INTEGRATION_CITIES, methodGuid)
  return (
    <MultiSearchChipField
      label="City"
      api={citiesApi}
      primaryKey="id"
      onValueChange={(value) => {
        onChange(null, value)
      }}
      params={serviceId ? { serviceId } : {}}
      {...props}
    />
  )
}

CarrierCitySearchField.propTypes = {
  onChange: PropTypes.func.isRequired
}

export default CarrierCitySearchField
