import { sprintf } from 'sprintf-js'
import { useAllSearchParams } from 'storfox-route-hooks'
import { useCreate, useDetail, useList, useUpdate } from 'storfox-api-hooks'
import { generatePath } from 'react-router-dom'

import { queryToParams } from '~/utils'
import * as API from '~/constants/api'

import { ContractFilterSerializer } from '../../serializers/Contract/index'

export const useContractList = () => {
  const searchParams = useAllSearchParams()

  const params = queryToParams(ContractFilterSerializer, searchParams)

  return useList(API.CONTRACT_LIST, { ...params })
}

export const useContractDetail = (id) => {
  const url = sprintf(API.CONTRACT_DETAIL, id)
  return useDetail(url)
}

export const useContractCreate = () => {
  return useCreate(API.CONTRACT_CREATE)
}

export const useContractCreateWithRetailer = () => {
  return useCreate(API.CONTRACT_CREATE_WITH_RETAILER)
}

export const useContractUpdate = (id) => {
  const url = sprintf(API.CONTRACT_UPDATE, id)
  return useUpdate(url)
}

export const useContractAccept = () => {
  const { update, ...state } = useUpdate(API.CONTRACT_DECIDE)

  return { ...state, accept: update }
}

export const useLoginByContract = () => {
  const { create, ...state } = useCreate(API.LOGIN_BY_CONTRACT)

  return { ...state, login: create }
}

export const useContractInvoices = (guid, autoSend) => {
  const url = sprintf(API.CONTRACT_INVOICE_LIST, guid)

  return useList(url, {}, autoSend)
}

export const useInvoiceDetail = (contractGuid, guid) => {
  const url = generatePath(API.CONTRACT_INVOICE_DETAIL, { contractGuid, guid })
  return useDetail(url)
}
